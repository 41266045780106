import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <defs>
        <path id="prefix__a" d="M0 0L15.987 0 15.987 13.998 0 13.998z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <g transform="translate(0 1)">
          <path
            className="icon-color"
            d="M7.993 11.48L3.197 6h9.592l-4.796 5.48zM4.375 2h7.49l1.291 2H2.94l1.435-2zm11.51 3.445c.018-.038.03-.079.045-.119.015-.047.032-.094.042-.143.006-.04.008-.08.011-.122.003-.05.005-.1.001-.149-.004-.04-.012-.08-.02-.122-.012-.05-.025-.099-.043-.147-.007-.018-.009-.038-.017-.057-.01-.02-.026-.038-.038-.059-.013-.023-.019-.048-.033-.07l-2.584-4C13.065.171 12.75 0 12.41 0H3.861c-.322 0-.625.155-.812.416L.181 4.417c-.02.028-.03.06-.048.09-.016.028-.037.05-.05.08-.007.012-.007.026-.013.038-.018.047-.029.096-.04.145-.01.042-.023.083-.028.126-.005.048 0 .096.001.145.002.043.001.086.01.13.006.047.024.093.04.14.013.042.023.084.043.123.003.008.003.016.007.023.023.043.055.078.083.118.02.026.032.057.054.083l7 7.998c.191.218.465.342.753.342s.562-.124.753-.342l7-7.998c.021-.025.033-.054.052-.08.024-.034.053-.063.073-.1.006-.01.008-.022.013-.033z"
            mask="url(#prefix__b)"
          />
        </g>
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="-3 -3 30 30">
      <defs>
        <path id="prefix__a" d="M0 0L23.984 0 23.984 22.002 0 22.002z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <g transform="translate(0 1)">
          <path
            className="icon-color"
            d="M11.992 19.384L3.05 8.003h17.884l-8.942 11.38zM5.999 2h12.4l2.709 4.003H2.993L5.999 2zm17.974 5.16c.007-.042.009-.085.01-.128.002-.047.002-.093-.004-.141-.005-.043-.013-.084-.024-.127-.011-.046-.025-.092-.044-.137-.008-.02-.01-.042-.02-.061-.01-.022-.027-.038-.04-.06-.011-.02-.017-.043-.031-.063L19.759.44c-.186-.275-.496-.44-.83-.44H5.5c-.315 0-.611.149-.8.4L.192 6.403c-.02.026-.03.056-.047.085-.016.027-.04.048-.052.078-.006.013-.008.028-.014.042-.02.045-.031.09-.045.138-.011.042-.023.084-.028.127-.007.047-.005.094-.005.142 0 .044 0 .088.006.13.007.048.02.092.035.137.012.044.023.087.043.128.005.014.006.028.012.04.017.033.04.058.06.088.018.028.028.058.049.083l11 13.999c.189.242.479.382.786.382.307 0 .597-.14.786-.382l11-14c.019-.022.027-.05.044-.075.017-.025.038-.046.053-.073.009-.016.01-.033.018-.05.019-.041.034-.083.046-.126.015-.045.027-.09.034-.136z"
            mask="url(#prefix__b)"
          />
        </g>
      </g>
    </svg>
  ),
};
