import React from 'react';

export default {
  sm: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M3.2925 6.30205C3.6815 5.91105 4.3165 5.91105 4.7055 6.30205L6.9955 8.59405L6.9955 2.00105C6.9955 1.45105 7.4455 1.00005 7.9955 1.00005C8.5475 1.00005 8.9955 1.45105 8.9955 2.00105L8.9955 8.59405L11.2865 6.30205C11.6765 5.91205 12.3125 5.91205 12.7005 6.30205C13.0905 6.69105 13.0905 7.32705 12.7005 7.71705L8.7025 11.7191C8.6455 11.7791 8.5705 11.8111 8.5035 11.8521C8.4615 11.8771 8.4275 11.9131 8.3815 11.9331C8.3405 11.9511 8.2945 11.9511 8.2515 11.9611C8.0385 12.0181 7.8145 12.0171 7.6105 11.9331C7.5665 11.9141 7.5335 11.8791 7.4925 11.8541C7.4235 11.8131 7.3505 11.7801 7.2885 11.7191L3.2925 7.71705C2.9025 7.32705 2.9025 6.69105 3.2925 6.30205Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M3 12.1429V13.0002H13.0007V12.1429C13.0007 12.14 13.0006 12.1357 13.0005 12.1303C12.9976 11.9873 12.9781 11 14.0007 11C15.0007 11 15.0007 12.0643 15.0007 12.1429V14.0002H15C15 14.0002 15 14.0002 15 14.0002C15 14.5502 14.55 15.0002 14 15.0002H2C1.45 15.0002 1 14.5502 1 14.0002V14.0001L1 12.1429L0.999808 12.1303C0.996978 11.9873 0.97744 11 2 11C3 11 3 12.0643 3 12.1429Z"
      />
    </svg>
  ),
  md: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M4 17.2V19.9998H20V17.2C20 17.0898 20 16 21 16C22 16 22 17.0898 22 17.2V21.0002C21.9998 21.0002 21.9997 21.0002 21.9995 21.0002C21.9993 21.55 21.5504 21.9998 20.9995 21.9998H2.99951C2.44951 21.9998 1.99951 21.5498 1.99951 20.9998C1.99951 20.9894 1.99968 20.9789 2 20.9685L2 17.2C2 17.0898 2 16 3 16C4 16 4 17.0898 4 17.2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M13.0009 14.5831L13.0009 3.00007C13.0009 2.44707 12.5529 2.00007 12.0009 2.00007C11.4479 2.00007 11.0009 2.44707 11.0009 3.00007L11.0009 14.5831L6.87885 10.4621C6.48785 10.0711 5.85485 10.0711 5.46485 10.4621C5.07385 10.8531 5.07385 11.4851 5.46485 11.8761L11.1219 17.5331C11.1509 17.5621 11.1819 17.5891 11.2129 17.6131C11.3969 17.8481 11.6809 17.9971 12.0009 17.9971C12.3199 17.9971 12.6049 17.8481 12.7879 17.6131C12.8189 17.5891 12.8509 17.5621 12.8789 17.5331L18.5359 11.8761C18.9269 11.4851 18.9269 10.8531 18.5359 10.4621C18.1459 10.0711 17.5129 10.0711 17.1219 10.4621L13.0009 14.5831Z"
      />
    </svg>
  ),
};
