import React from 'react';

export default {
  sm: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M12.7005 6.69844C12.3115 7.08944 11.6765 7.08944 11.2875 6.69844L8.9975 4.40644V10.9994C8.9975 11.5494 8.5475 12.0004 7.9975 12.0004C7.4455 12.0004 6.9975 11.5494 6.9975 10.9994V4.40644L4.7065 6.69844C4.3165 7.08844 3.6805 7.08844 3.2925 6.69844C2.9025 6.30944 2.9025 5.67344 3.2925 5.28344L7.2905 1.28144C7.3475 1.22144 7.4225 1.18944 7.4895 1.14844C7.5315 1.12344 7.5655 1.08744 7.6115 1.06744C7.6525 1.04944 7.6985 1.04944 7.7415 1.03944C7.9545 0.982438 8.1785 0.983438 8.3825 1.06744C8.4265 1.08644 8.4595 1.12144 8.5005 1.14644C8.5695 1.18744 8.6425 1.22044 8.7045 1.28144L12.7005 5.28344C13.0905 5.67344 13.0905 6.30944 12.7005 6.69844Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M3 12.1429V13.0002H13.0007V12.1429C13.0007 12.14 13.0006 12.1357 13.0005 12.1303C12.9976 11.9873 12.9781 11 14.0007 11C15.0007 11 15.0007 12.0643 15.0007 12.1429V14.0002H15C15 14.0002 15 14.0002 15 14.0002C15 14.5502 14.55 15.0002 14 15.0002H2C1.45 15.0002 1 14.5502 1 14.0002V14.0001L1 12.1429L0.999808 12.1303C0.996978 11.9873 0.97744 11 2 11C3 11 3 12.0643 3 12.1429Z"
      />
    </svg>
  ),
  md: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M4 17.2V19.9998H20V17.2C20 17.0898 20 16 21 16C22 16 22 17.0898 22 17.2V21.0002C21.9998 21.0002 21.9997 21.0002 21.9995 21.0002C21.9993 21.55 21.5504 21.9998 20.9995 21.9998H2.99951C2.44951 21.9998 1.99951 21.5498 1.99951 20.9998C1.99951 20.9894 1.99968 20.9789 2 20.9685L2 17.2C2 17.0898 2 16 3 16C4 16 4 17.0898 4 17.2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className="icon-color"
        d="M10.9996 5.414V16.997C10.9996 17.55 11.4476 17.997 11.9996 17.997C12.5526 17.997 12.9996 17.55 12.9996 16.997V5.414L17.1216 9.535C17.5126 9.926 18.1456 9.926 18.5356 9.535C18.9266 9.144 18.9266 8.512 18.5356 8.121L12.8786 2.464C12.8496 2.435 12.8186 2.408 12.7876 2.384C12.6036 2.149 12.3196 2 11.9996 2C11.6806 2 11.3956 2.149 11.2126 2.384C11.1816 2.408 11.1496 2.435 11.1216 2.464L5.46464 8.121C5.07364 8.512 5.07364 9.144 5.46464 9.535C5.85464 9.926 6.48764 9.926 6.87864 9.535L10.9996 5.414Z"
      />
    </svg>
  ),
};
